import { defineStore } from "pinia";
import { useI18n } from "vue-i18n";

export const useBetaStore = defineStore("beta", () => {
  const enabled = useLocalStorage<boolean>("ss-beta-enabled", false);

  const { t } = useI18n();
  const profile = useProfileStore();
  const api = useApiStore();

  function getAlphaEnabled() {
    const params = new URLSearchParams(window.location.search);
    const alpha = params.get("alpha");
    return alpha === "1";
  }
  const enableAlphaFeatures = ref(getAlphaEnabled());

  const enableBetaToggle = import.meta.env.VITE_ENABLE_BETA_TOGGLE === "1";

  const featureLUT = computed<{
    [key: string]: { env: string; description: string | string[] };
  }>(() => {
    return {
      recordingFocusLayout: {
        env: "VITE_ENABLE_RECORDING_FOCUS_SONG_DETAIL_LAYOUT",
        description: t("SV.MES_NewFeaturesRecordingFocus"), // Chords, Lead sheets, and Vocal sheets based on recording.
      },
      fullSongPlayback: {
        env: "VITE_ENABLE_FULL_SONG_PLAYBACK",
        description:
          import.meta.env.VITE_ENABLE_SPOTIFY === "1"
            ? t("SV.MES_NewFeaturesFullSongPlayback")
            : t("SV.MES_NewFeaturesFullSongPlaybackAppleMusicOnly"),
        //"Connect your Apple Music or Spotify account to enable full song playback.",
      },
      ccliTop100New: {
        env: "VITE_ENABLE_CCLI_TOP_100_NEW",
        description: t("SV.MES_NewFeaturesCCLITop100Homepage"),
      },

      inviteUser: {
        env: "VITE_ENABLE_PROFILE_INVITE_USER",
        description: t("SV.MES_NewFeaturesInviteUsers"),
      },

      chordFontSettings: {
        env: "VITE_ENABLE_SHEETMUSIC_CHORDS_FONT_SETTINGS",
        description: t("SV.MES_NewFeaturesCustomizedFontsLyricsChords"), //"Customized fonts for lyrics and chords.",
      },
      midiScrollImprovements: {
        env: "VITE_ENABLE_SHEETMUSIC_MIDI_SCROLL_IMPROVEMENTS",
        description: t("SV.MES_NewFeaturesAutoScrollMidi"),
      },

      /** these are now officially on always - not new */

      lyricFontSettings: {
        env: "VITE_ENABLE_SHEETMUSIC_LYRIC_FONT_SETTINGS",
        description: "",
      },
      recentlyViewed: {
        env: "VITE_ENABLE_HOME_RECENTLY_VIEWED",
        description: t("SV.MES_NewFeaturesSeeRecentlyViewed"),
      },
      sharedFavorites: {
        env: "VITE_ENABLE_SHARED_FAVORITES",
        description: t("SV.MES_NewFeaturesCreateAndSaveSongLists"),
      },
      sheetmusicPipeline: {
        env: "VITE_ENABLE_SHEETMUSIC_RENDERER_NEW_PIPELINE",
        description:
          "Enable the use of the new sheetmusic pipeline for rendering lead/vocal sheets.",
      },
    };
  });

  const features = ref<{ [key: string]: boolean }>({});
  const featuresInNew = computed(() => {
    const features: (typeof featureLUT.value)[] = [];
    Object.keys(featureLUT.value).forEach((key) => {
      if (isFeatureToggleIn((featureLUT.value as any)[key].env, "new")) {
        features.push((featureLUT.value as any)[key]);
      }
    });
    return features;
  });

  const featuresInBeta = computed(() => {
    const features: (typeof featureLUT.value)[] = [];
    Object.keys(featureLUT.value).forEach((key) => {
      if (isFeatureToggleIn((featureLUT.value as any)[key].env, "beta")) {
        features.push((featureLUT.value as any)[key]);
      }
    });
    return features;
  });
  const showBetaToggle = computed(
    () => enableBetaToggle && featuresInBeta.value.length > 0,
  );

  const featureDescriptionsInBeta = computed(() =>
    featuresInBeta.value
      .flatMap((feature) => feature.description)
      .filter((f) => !!f),
  );

  const featureDescriptionsInNew = computed(() =>
    featuresInNew.value
      .flatMap((feature) => feature.description)
      .filter((f) => !!f),
  );
  const initialized = ref(false);

  function getFeatures() {
    const enabledFeatures: { [key: string]: boolean } = {};

    Object.keys(featureLUT.value).forEach((key) => {
      enabledFeatures[key] = isFeatureToggleEnabled(
        (featureLUT.value as any)[key].env,
        enabled.value,
      );
    });

    features.value = enabledFeatures;
  }
  getFeatures();
  watch(enabled, getFeatures);
  watch(enabled, updateToProfile);

  function init(profileIsEnabled: boolean) {
    enabled.value = profileIsEnabled;
    initialized.value = true;
    getFeatures();
  }

  function updateToProfile() {
    if (profile.profile?.signedIn && initialized.value) {
      api.post("/UpdateBetaToggle", {
        betaToggle: enabled.value,
      });
    } else {
      initialized.value = false;
    }
  }

  function isFeatureToggleIn(name: string, state: string) {
    const val: string = import.meta.env[name];
    if (val.toLowerCase() === state) {
      return true;
    }
    return false;
  }

  function isFeatureToggleEnabled(name: string, betaEnabled: boolean): boolean {
    const val: string = import.meta.env[name];
    if (val) {
      if (
        val === "1" ||
        val.toLowerCase() === "on" ||
        val.toLowerCase() === "new"
      ) {
        return true;
      }
      if (val.toLowerCase() === "alpha") {
        return enableAlphaFeatures.value;
      }
      if (val.toLowerCase() === "beta") {
        if (!enableBetaToggle) {
          return false;
        }
        if (profile.profile?.signedIn) {
          return betaEnabled;
        } else {
          return false;
        }
      }
      if (val === "0" || val.toLowerCase() === "off") {
        return false;
      }
      throw new Error(`Feature toggle state not recognized: ${name}:${val} `);
    }
    return false;
  }

  return {
    enabled,
    features,
    enableAlphaFeatures,
    featureDescriptionsInBeta,
    featureDescriptionsInNew,
    showBetaToggle,
    init,
  };
});
