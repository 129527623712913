<script setup lang="ts">
const winSize = useWindowSize();
const { t } = useI18n();

const input = ref<HTMLInputElement>();
const props = withDefaults(
  defineProps<{
    modelValue: string;
    id?: string;
    alwaysShow?: boolean;
  }>(),
  {
    id: () => uniqueId("SearchTextInput"),
  },
);
const emit = defineEmits<{
  (e: "submit"): void;
  (e: "blur"): void;
  (e: "update:modelValue", val: string): void;
}>();

const search = computed<string>({
  get: () => props.modelValue,
  set: (val) => {
    emit("update:modelValue", val);
  },
});

const inputFocused = useFocus(input);
const inputFocusedDelayed = useDebounce(inputFocused.focused, 100);
const ignoreClick = computed(() => !inputFocusedDelayed.value);

function onSubmit() {
  emit("submit");
}

const searchPlaceholder = computed(() =>
  winSize.width.value > 991
    ? t("SV.MES_SearchSongSelect")
    : t("SV.LIT_SearchSongSelect"),
);

function focus() {
  input.value?.focus();
}

function onBlur() {
  emit("blur");
}

defineExpose({
  focus,
});
</script>

<template>
  <form
    name="search-form"
    :class="{ 'always-show': alwaysShow }"
    @submit.stop.prevent="onSubmit"
  >
    <div class="input-wrapper">
      <button
        ref="iconBtn"
        type="submit"
        :title="$t('G.LIT_Search')"
        class="input-search-btn"
        :class="{ 'ignore-click': ignoreClick }"
      >
        <FAIcon
          id="search-icon"
          class="fa-light fa-magnifying-glass"
        />
      </button>

      <label
        :for="id"
        class="hidden"
        >{{ $t("SV.LIT_SearchSongSelect") }}</label
      >
      <input
        :id="id"
        ref="input"
        v-model="search"
        type="text"
        class="input"
        :placeholder="searchPlaceholder"
        @keydown.enter="onSubmit"
        @blur="onBlur"
      />
    </div>
  </form>
</template>

<style scoped lang="postcss">
form {
  @apply w-full;
}
form:not(.always-show) {
  @apply hidden lg:block;
}
form.always-show {
  @apply block;
}
input {
  @apply flex-shrink-0 rounded-full bg-light-500 py-2 pl-10 focus:border-none;
  height: unset;
}
.input-search-btn {
  @apply absolute left-4 top-1/2 -mt-3 text-primary;
}
.input-wrapper {
  @apply relative;
}
form.has-focus {
  @apply block;
}
.ignore-click {
  @apply pointer-events-none;
}
</style>
