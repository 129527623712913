<script setup lang="ts">
const modals = useModalStore();
const profile = useProfileStore();

function onClick() {
  if (beta.showBetaToggle && signedIn.value) {
    modals.showModal("NewFeaturesModal");
  } else {
    modals.showModal("WelcomeModal");
  }
}
const beta = useBetaStore();

function onChange() {
  (window as any).dataLayer.push({
    event: "betaUserToggle",
    is_beta_user: beta.enabled,
  });
}

const signedIn = computed(() => profile.profile?.signedIn || false);
</script>

<template>
  <button
    v-if="beta.showBetaToggle && signedIn"
    type="button"
    title="Learn more about the latest features"
    class="new-site-toggle"
    @click.stop.prevent="onClick"
  >
    <FAIcon class="fa-light fa-info-circle text-primary" />
    <span class="text-label"
      >{{ $t("SV.LIT_TryOutOurNewFeatures") }}
      <span
        v-if="beta.enabled"
        class="text-xs text-primary"
        >{{ $t("G.LIT_Active") }}</span
      ></span
    >
  </button>
  <button
    v-else
    type="button"
    title="Learn more about the latest enhancements"
    class="welcome-toggle"
    @click="onClick"
  >
    <FAIcon class="fa-light fa-info-circle text-primary" />

    <div class="text-label">
      {{ $t("SV.LIT_SeeWhatsNew") }}
    </div>
  </button>
</template>

<style scoped lang="postcss">
.new-site-toggle {
  @apply flex w-full flex-row items-center justify-center gap-2 bg-blue-100 px-5 py-6 text-left text-base-content;
}
.welcome-toggle {
  @apply flex w-full items-center gap-3 bg-blue-100 py-6 pl-7 pr-3 text-left text-base-content;
}

html.dark .welcome-toggle {
  @apply bg-ccli-blue3;
}

.text-label {
  @apply leading-4;
}
button {
  @apply text-primary transition-all;
  &:hover,
  &:focus-visible {
    @apply brightness-95;
  }
}
</style>
